import { useEffect, useMemo, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useAuth0 } from '@auth0/auth0-react'
import { useSearchParams } from 'react-router-dom'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import pick from 'lodash/pick'
import { formatDate } from '../../../utils/formatting'

import { AppLayoutContext } from '../../../providers/AppLayout'
import { useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { ManualTrailStatus, manualTrailStatusFields } from '../../../models/ManualTrailStatus.model'
import { ManualTrailStatusRelations } from '../../../models/ManualTrailStatusRelations.model'
import { TrailSystem } from '../../../models/TrailSystem.model'
import { Trail } from '../../../models/Trail.model'
import { TrailStatuses } from '../../../models/TrailStatuses.model'

import UserService from '../../../services/UserService/UserService'
import { ManualTrailStatusService } from '../../../services/ManualTrailStatusService/ManualTrailStatusService'
import { TrailSystemService } from '../../../services/TrailSystemService/TrailSystemService'
import { TrailService } from '../../../services/TrailService/TrailService'

import {
  Box,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Paper,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DataTable from '../../../components/DataTable/DataTable.component'
import Loading from '../../../components/Loading/Loading.component'
import ImportExportDialog, {
  ImportExportDialogProps,
} from '../../../components/ImportExportDialog/ImportExportDialog.component'
import Link from '@mui/material/Link'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import ManualTrailStatusForm from '../shared/ManualTrailStatusForm.component'
import TrailPicker from '../../../components/TrailPicker/TrailPicker.component'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AppStateContext } from '../../../providers/AppStateContext'
import { TrailRelations } from '../../../models/TrailRelations.model'

type ManualTrailStatusSearchProps = {
  nested?: boolean
  systemId?: number
  trailId?: number
  hideHeader?: boolean
  excludeColumns?: string[]
}
const ManualTrailStatusSearch = (props: ManualTrailStatusSearchProps): JSX.Element => {
  const [currentSearchParameters] = useSearchParams()
  const {
    nested,
    systemId = Number(currentSearchParameters.get('trail-system')) || undefined,
    trailId = Number(currentSearchParameters.get('trail')) || undefined,
    hideHeader = !systemId && !trailId,
    excludeColumns = [],
  } = props

  const [isSaving, setIsSaving] = useState(false)

  const { user } = useAuth0()
  const formattedUser = useMemo(() => (user ? UserService.formatUser(user) : undefined), [user])
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editManualTrailStatus])

  const [searchError, setSearchError] = useState('')
  const [rowActionsAnchorEl, setRowActionsAnchorEl] = useState<null | HTMLElement>(null)
  const isActionsMenuOpen = Boolean(rowActionsAnchorEl)
  const [selectedRow, setSelectedRow] = useState<ManualTrailStatus | undefined>()
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const [isPickingTrails, setIsPickingTrails] = useState(false)

  const [tempTrails, setTempTrails] = useState<Trail[]>([])
  const [selectedTrails, setSelectedTrails] = useState<Trail[]>([])
  const [tempTrailSystems, setTempTrailSystems] = useState<TrailSystem[]>([])
  const [selectedTrailSystems, setSelectedTrailSystems] = useState<TrailSystem[]>([])

  const [reason, setReason] = useUrlStateParams(
    '',
    'reason',
    (value) => encodeURIComponent(value.toString()),
    (value) => (value ? decodeURIComponent(value) : ''),
  )
  const reasonInputRef = useRef<HTMLInputElement>()
  const [orderBy, setOrderBy] = useState<keyof ManualTrailStatus | undefined>('start')
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc' | undefined>('desc')
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 1),
  )
  const [pageSize, setPageSize] = useUrlStateParams(
    12,
    'page-size',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 12),
  )
  const [includePast, setIncludePast] = useUrlStateParams(
    false,
    'include-past',
    (value) => value.toString(),
    (value) => value === 'true',
  )
  const [includeFuture, setIncludeFuture] = useUrlStateParams(
    true,
    'include-future',
    (value) => value.toString(),
    (value) => value === 'true',
  )

  const [trailSystem, setTrailSystem] = useState<TrailSystem>()
  const [trail, setTrail] = useState<Trail>()

  const [isImportExportOpen, setIsImportExportOpen] = useState(false)

  const manualTrailStatusService = useMemo(() => new ManualTrailStatusService(), []) // memo to prevent re-creating service on every render
  const trailService = useMemo(() => new TrailService(), []) // memo to prevent re-creating service on every render
  const trailSystemService = useMemo(() => new TrailSystemService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    if (!nested) {
      AppLayoutContext.setPageName('Manual Statuses')
    }

    if (systemId) {
      // fetch the trail system for use in the add dialog
      trailSystemService
        .get({ id: systemId, relations: [] })
        .then((system) => {
          setTrailSystem(system)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    if (trailId) {
      // fetch the trail for use in the add dialog
      trailService
        .get({ id: trailId, relations: [TrailRelations.system] })
        .then((trail) => {
          setTrail(trail)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [])

  const queryClient = useQueryClient()
  const { isLoading, data: manualTrailStatusesResponse } = useQuery(
    [
      'ManualTrailStatusService.search',
      {
        systemIds: [...selectedTrailSystems.map((trailSystem) => trailSystem.id), systemId].filter(
          (id) => id,
        ) as number[],
        trailIds: [...selectedTrails.map((trail) => trail.id), trailId].filter((id) => id) as number[],
        page,
        pageSize,
        reason,
        orderBy,
        orderDirection,
        relations: [ManualTrailStatusRelations.system, ManualTrailStatusRelations.trail],
        includePast,
        includeFuture,
      },
      AppStateContext.getRegion(),
    ],
    () =>
      manualTrailStatusService.search({
        systemIds: [...selectedTrailSystems.map((trailSystem) => trailSystem.id), systemId].filter(
          (id) => id,
        ) as number[],
        trailIds: [...selectedTrails.map((trail) => trail.id), trailId].filter((id) => id) as number[],
        page,
        pageSize,
        reason,
        orderBy,
        orderDirection,
        relations: [ManualTrailStatusRelations.system, ManualTrailStatusRelations.trail],
        includePast,
        includeFuture,
      }),
    {
      onError: (err) => {
        setSearchError(typeof err === 'string' ? err : 'An error occurred while searching for trail systems.')
        console.error(err)
      },
    },
  )

  useEffect(() => {
    if (
      manualTrailStatusesResponse &&
      page > 1 &&
      (!manualTrailStatusesResponse.total || (page - 1) * pageSize >= manualTrailStatusesResponse.total)
    ) {
      // page out of range was requested so reset to page 1
      setPage(1)
    }
  }, [manualTrailStatusesResponse])

  const handleRowActionsClick = (event: React.MouseEvent<HTMLButtonElement>, manualTrailStatus: ManualTrailStatus) => {
    setRowActionsAnchorEl(event.currentTarget)
    setSelectedRow(manualTrailStatus)
  }
  const handleRowActionsClose = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
  }

  const columns = useMemo(() => {
    const columns: GridColDef<ManualTrailStatus>[] = [
      {
        field: 'actions',
        headerName: '',
        width: 60,
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton
              id={`row-actions-button-${params.row.id}`}
              color="primary"
              aria-label="row actions"
              aria-controls={selectedRow?.id === params.row.id ? 'row-actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={selectedRow?.id === params.row.id ? 'true' : undefined}
              onClick={(event) => handleRowActionsClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        renderCell: (params) => (
          <Link
            color="primary"
            aria-label="manual trail status detail"
            href={`/manual-trail-statuses/${params.row.id}`}
          >
            {params.row.id}
          </Link>
        ),
      },
      {
        field: 'system',
        headerName: 'Assigned To',
        sortable: false, // cant sort by relational fields
        width: 300,
        renderCell: (params) => {
          return (
            <>
              {params.row.system?.id && <Box>System: {params.row.system?.name}</Box>}
              {params.row.trail?.id && <Box>Trail: {params.row.trail?.name}</Box>}
            </>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: (params) => {
          const validStatus = Object.entries(TrailStatuses).find((trailStatus) => trailStatus[1] === params.row.status)
          return validStatus ? startCase(camelCase(validStatus[0])) : 'Unknown'
        },
      },
      {
        field: 'start',
        headerName: 'Start',
        width: 200,
        renderCell: (params) => (params.row.start ? formatDate(params.row.start) : 'Immediately'),
      },
      {
        field: 'end',
        headerName: 'End',
        width: 200,
        renderCell: (params) => (params.row.end ? formatDate(params.row.end) : 'Never'),
      },
      {
        field: 'reason',
        headerName: 'Reason',
        width: 400,
      },
    ]
    // remove any columns specified to be removed in the excludeColumns prop
    if (excludeColumns) {
      return columns.filter((column) => !excludeColumns.includes(column.field as string))
    }
    return columns
  }, [excludeColumns])

  const handleSubmit = () => {
    setPage(1)
    setReason(reasonInputRef?.current?.value || '')
    setSelectedTrails(tempTrails)
    setSelectedTrailSystems(tempTrailSystems)
  }

  const handleAddClick = () => {
    setIsAdding(true)
  }

  const handleAddCancel = () => {
    setIsAdding(false)
  }

  const handleAddSubmit = () => {
    setIsAdding(false)
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleEditCancel = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleEditSubmit = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false)
    handleRowActionsClose()
  }

  const deleteManualTrailStatus = useMutation(
    (mutationParams: { id: number }) => {
      setIsSaving(true)
      return manualTrailStatusService.delete(mutationParams.id)
    },
    {
      onSuccess: () => {
        SnackbarContext.show('Manual Status deleted successfully!')
        setRowActionsAnchorEl(null)
        setSelectedRow(undefined)
        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSaving(false)
      },
      onError: (err) => {
        SnackbarContext.show(`Manual Status failed to delete: ${err}`, 'error')
        console.error(err)
        setIsSaving(false)
      },
    },
  )

  const handleDelete = () => {
    if (selectedRow?.id) {
      setIsDeleteConfirmationOpen(false)
      deleteManualTrailStatus.mutate({ id: selectedRow.id })
    }
  }

  const openImportExport = () => {
    setIsImportExportOpen(true)
  }

  const handleImportExportClose = () => {
    setIsImportExportOpen(false)
  }

  const handleImport: ImportExportDialogProps['onImport'] = (data: Partial<ManualTrailStatus>[], options) => {
    setIsImportExportOpen(false)
    if (!data) {
      SnackbarContext.show('Failed to read/parse file', 'error')
      console.error('Failed to read/parse file')
    } else {
      setIsSaving(true)
      manualTrailStatusService
        .import(data, options)
        .then(() => {
          SnackbarContext.show('Manual Statuses imported successfully!')

          // fetch new data
          setSearchError('')
          // invalidate all queries to ensure any queries that are using the modified record are updated
          queryClient.invalidateQueries()
          setIsSaving(false)
        })
        .catch((err) => {
          SnackbarContext.show(`Manual Statuses failed to import: ${err}`, 'error')
          console.error(err)
          setIsSaving(false)
        })
    }
  }

  const handleExport = () => {
    // do a request to the API to get the data to export with no pagination
    setIsSaving(true)
    setIsImportExportOpen(false)
    manualTrailStatusService
      .search({
        systemIds: [...selectedTrailSystems.map((trailSystem) => trailSystem.id), systemId].filter(
          (id) => id,
        ) as number[],
        trailIds: [...selectedTrails.map((trail) => trail.id), trailId].filter((id) => id) as number[],
        pageSize: 0,
        reason,
        orderBy,
        orderDirection,
        relations: [ManualTrailStatusRelations.system, ManualTrailStatusRelations.trail],
        includePast,
        includeFuture,
      })
      .then((results) => {
        const exportData = results.data.map((manualTrailStatus) => {
          const manualTrailStatusToExport: Record<string, unknown> = { ...manualTrailStatus }
          // set a prop for system's or trail's id and name and remove the system object prop
          manualTrailStatusToExport.systemId = manualTrailStatus.system?.id
          manualTrailStatusToExport.systemName = manualTrailStatus.system?.name
          manualTrailStatusToExport.trailId = manualTrailStatus.trail?.id
          manualTrailStatusToExport.trailName = manualTrailStatus.trail?.name
          delete manualTrailStatusToExport.system
          delete manualTrailStatusToExport.trail
          // set a column for the friendly status name
          const validStatus = Object.entries(TrailStatuses).find(
            (trailStatus) => trailStatus[1] === manualTrailStatus.status,
          )
          manualTrailStatusToExport.statusFriendly = validStatus ? startCase(camelCase(validStatus[0])) : 'Unknown'

          manualTrailStatusToExport.start =
            manualTrailStatusToExport.start instanceof Date ? manualTrailStatusToExport.start.toISOString() : ''
          manualTrailStatusToExport.end =
            manualTrailStatusToExport.end instanceof Date ? manualTrailStatusToExport.end.toISOString() : ''
          manualTrailStatusToExport.updatedAt =
            manualTrailStatusToExport.updatedAt instanceof Date ? manualTrailStatusToExport.updatedAt.toISOString() : ''
          manualTrailStatusToExport.createdAt =
            manualTrailStatusToExport.createdAt instanceof Date ? manualTrailStatusToExport.createdAt.toISOString() : ''

          // return the object with the props in the order we want them to appear in the csv
          const propsToExport: string[] = [...manualTrailStatusFields]
          // add system and trail props after id
          propsToExport.splice(propsToExport.indexOf('region') + 1, 0, 'systemId', 'systemName', 'trailId', 'trailName')
          // add statusFriendly after status
          propsToExport.splice(propsToExport.indexOf('status') + 1, 0, 'statusFriendly')
          return pick(manualTrailStatusToExport, propsToExport)
        })
        const csvData = d3.csvFormat(exportData)
        // initiate a download of the csv file
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.setAttribute('href', url)
        link.setAttribute('download', 'manual-trail-statuses.csv')
        link.click()
        setIsSaving(false)
      })
      .catch((err) => {
        SnackbarContext.show(`Failed to fetch manual statuses for export: ${err}`, 'error')
        console.error(err)
      })
  }

  return (
    <>
      {selectedRow && (
        <FormDialog<ManualTrailStatus>
          open={isEditing}
          entity={selectedRow}
          entityLabel="Manual Trail Status"
          onClose={handleEditCancel}
        >
          <ManualTrailStatusForm
            manualTrailStatus={selectedRow}
            onEdit={handleEditSubmit}
            onCancel={handleEditCancel}
          />
        </FormDialog>
      )}
      <FormDialog<ManualTrailStatus> open={isAdding} entityLabel="Manual Trail Status" onClose={handleAddCancel}>
        <ManualTrailStatusForm
          trail={trail}
          trailSystem={trailSystem}
          onAdd={handleAddSubmit}
          onCancel={handleAddCancel}
        />
      </FormDialog>
      <Dialog fullWidth maxWidth="xs" open={isDeleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete manual status id {selectedRow?.id}: {selectedRow?.reason}?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteConfirmationClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <ImportExportDialog
        open={isImportExportOpen}
        disableImport={!!formattedUser?.trailSystems.length || !hasWritePermissions}
        exampleImportFileUrl="/example-import-files/manual-trail-statuses.csv"
        importFields={[
          ...manualTrailStatusFields.filter((field) => !['system', 'trail', 'updatedAt', 'createdAt'].includes(field)),
          'systemId',
          'trailId',
        ]}
        onImport={handleImport}
        onExport={handleExport}
        onClose={handleImportExportClose}
      />
      <Paper sx={{ p: 2 }}>
        {!hideHeader && (
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            {trailSystem?.name ? `${trailSystem.name} ` : trail?.name ? `${trail.name} ` : ''}Manual Statuses
          </Typography>
        )}
        {(isLoading || isSaving) && !searchError && <Loading sx={{ py: 20 }} />}
        {searchError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{searchError}</Typography>
          </Stack>
        )}
        {!isLoading && !isSaving && !searchError && (
          <>
            <Box sx={{ pt: 1, mb: 1 }}>
              <Stack direction={{ zero: 'column', md: 'row' }} spacing={1}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel>Reason</InputLabel>
                  <OutlinedInput
                    id="search-reason"
                    label="Reason"
                    defaultValue={reason || ''}
                    inputRef={reasonInputRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit()
                      }
                    }}
                    endAdornment={
                      reasonInputRef?.current?.value ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear search reason"
                            onClick={() => {
                              if (reasonInputRef?.current?.value) {
                                reasonInputRef.current.value = ''
                              }
                              setPage(1)
                              setReason('')
                            }}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : undefined
                    }
                  />
                </FormControl>

                {!systemId && !trailId && (
                  <>
                    <Dialog
                      fullWidth
                      maxWidth="md"
                      open={isPickingTrails}
                      onClose={() => {
                        handleSubmit()
                        setIsPickingTrails(false)
                      }}
                    >
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                          <Typography sx={{ mr: 2, flex: 1 }} variant="h6" component="div">
                            Search by Trail
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Button
                              color="primary"
                              variant="text"
                              aria-label="clear"
                              onClick={() => {
                                setPage(1)
                                setTempTrails([])
                                setSelectedTrails([])
                                setTempTrailSystems([])
                                setSelectedTrailSystems([])
                                setIsPickingTrails(false)
                              }}
                            >
                              Clear
                            </Button>
                            <Button
                              color="primary"
                              variant="outlined"
                              aria-label="apply"
                              onClick={() => {
                                handleSubmit()
                                setIsPickingTrails(false)
                              }}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </Toolbar>
                      </AppBar>
                      <DialogContent>
                        <TrailPicker
                          selectedTrails={tempTrails}
                          setSelectedTrails={setTempTrails}
                          setSelectedTrailSystems={setTempTrailSystems}
                        />
                      </DialogContent>
                    </Dialog>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setIsPickingTrails(true)
                      }}
                      sx={(theme) => ({ [theme.breakpoints.up('md')]: { width: 250 }, maxWidth: '100%' })}
                    >
                      Select Trails{tempTrails.length > 0 ? ` (${tempTrails.length})` : ''}
                    </Button>
                  </>
                )}

                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  sx={(theme) => ({ [theme.breakpoints.up('md')]: { width: 150 }, maxWidth: '100%' })}
                >
                  Search
                </Button>
              </Stack>
              <Stack
                direction={{ default: 'column', sm: 'row' }}
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{ mt: 1 }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  {hasWritePermissions && (!systemId || (systemId && trailSystem)) && (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      color="primary"
                      aria-label="add new"
                      onClick={handleAddClick}
                    >
                      Add
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    startIcon={<ImportExportIcon />}
                    color="primary"
                    aria-label="show import export"
                    onClick={() => {
                      openImportExport()
                    }}
                  >
                    {formattedUser && !formattedUser.trailSystems.length ? 'Import / ' : ''}Export
                  </Button>
                </Stack>
                <Stack direction={{ default: 'column', sm: 'row' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={includePast}
                          onChange={(event) => {
                            setIncludePast(event.target.checked)
                          }}
                        />
                      }
                      label="Include Past"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={includeFuture}
                          onChange={(event) => {
                            setIncludeFuture(event.target.checked)
                          }}
                        />
                      }
                      label="Include Future"
                    />
                  </FormGroup>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      if (reasonInputRef?.current?.value) {
                        reasonInputRef.current.value = ''
                      }
                      setPage(1)
                      setReason('')
                      setTempTrails([])
                      setSelectedTrails([])
                      setTempTrailSystems([])
                      setSelectedTrailSystems([])
                    }}
                  >
                    Clear
                  </Button>
                </Stack>
              </Stack>
            </Box>

            {manualTrailStatusesResponse?.data && manualTrailStatusesResponse.data.length > 0 ? (
              <>
                <Menu
                  id="row-actions-menu"
                  anchorEl={rowActionsAnchorEl}
                  open={isActionsMenuOpen}
                  onClose={handleRowActionsClose}
                  MenuListProps={{
                    'aria-labelledby': `row-actions-button${selectedRow?.id}`,
                  }}
                >
                  <MenuItem href={`/manual-trail-statuses/${selectedRow?.id}`} component={Link}>
                    View
                  </MenuItem>
                  {hasWritePermissions && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
                  {hasWritePermissions && <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>}
                </Menu>
                <DataTable
                  columns={columns}
                  data={manualTrailStatusesResponse.data}
                  total={manualTrailStatusesResponse.total}
                  paginationMode="server"
                  page={page}
                  pageSize={pageSize}
                  onPageChange={(newPage) => {
                    setPage(newPage)
                  }}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(newOrder) => {
                    setOrderBy(newOrder.field ? (newOrder.field as keyof ManualTrailStatus) : undefined)
                    setOrderDirection(newOrder.sort)
                  }}
                />
              </>
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography>No results found</Typography>
              </Box>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default ManualTrailStatusSearch
